import * as yup from 'yup'

import { generateValidation } from '~shared/validation/generator'

export const [newDriverRequiredSchema, newDriverRequiredResolver] =
  generateValidation(
    yup.object({
      extraInfoNeeded: yup.mixed(),
      hasInterview: yup.mixed(),
      email: yup.string().email().required().nullable().label('Email address'),
      customerId: yup.string().required().nullable().label('Customer'),
      depotId: yup.string().required().nullable().label('Depot'),
      firstName: yup
        .string()
        .when(
          'extraInfoNeeded',
          (val: boolean | number, schema: yup.StringSchema) =>
            val ? schema.required() : schema,
        )
        .nullable()
        .label('First Name'),
      lastName: yup
        .string()
        .when(
          'extraInfoNeeded',
          (val: boolean | number, schema: yup.StringSchema) =>
            val ? schema.required() : schema,
        )
        .nullable()
        .label('Last Name'),
      phoneNumber: yup
        .string()
        .max(20, 'The phone number cannot be more than 20 digits long')
        .matches(/^(?:0|\+44)/, 'The phone number must start with "0" or "+44"')
        .matches(
          /^(?:0|\+)\d+([\d ]*)$/,
          'The phone number must contain only numbers, spaces, or a leading + (country code)',
        )
        .when(
          'extraInfoNeeded',
          (val: boolean | number, schema: yup.StringSchema) =>
            val ? schema.required() : schema,
        )
        .nullable()
        .label('Phone Number'),
      externalID: yup.string().nullable().label('External ID'),
      date: yup
        .date()
        .when('hasInterview', (val: boolean | number, schema: yup.DateSchema) =>
          val ? schema.required() : schema,
        )
        .nullable()
        .label('Date'),
      time: yup
        .string()
        .when(
          'hasInterview',
          (val: boolean | number, schema: yup.StringSchema) =>
            val ? schema.required() : schema,
        )
        .nullable()
        .label('Time'),
      roleId: yup.string().required().nullable().label('Role'),
    }),
  )
