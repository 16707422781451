import { get } from 'lodash'
import * as yup from 'yup'
import { AssertsShape, ObjectShape, TypeOfShape } from 'yup/lib/object'
import { AnyObject, Maybe } from 'yup/lib/types'

const DEFAULT_MESSAGE_JOINER = (messages: string[]) =>
  messages.map((m) => `• ${m}`).join('\n')

const parseValidationError = (
  error: yup.ValidationError,
  data: unknown,
  messageJoiner: (messages: string[]) => string,
): Record<string, string> => {
  const errors = error.inner.reduce((acc, err) => {
    if (!err.path) return acc
    const simplePath = err.path.replace(/\[([^\]]+)\]/g, '.$1')
    const indexOfFirstPath = simplePath.matchAll(/\.(\d+)/g)

    const formattedPath = Array.from(indexOfFirstPath)
      .reverse()
      .reduce((path, match) => {
        const index = match.index
        if (!index) return path
        const id = String(
          get(data, path.slice(0, index + match[0].length) + '.id', ''),
        )
        if (!id) return path
        return (
          path.slice(0, index) + `.${id}` + path.slice(index + match[0].length)
        )
      }, simplePath)

    acc[formattedPath] = (acc[formattedPath] || []).concat(err.message)
    return acc
  }, {} as Record<string, string[]>)
  return Object.keys(errors).reduce((prev, key) => {
    prev[key] = messageJoiner(errors[key])
    return prev
  }, {} as Record<string, string>)
}

export const validatePath = <
  S extends ObjectShape,
  C extends AnyObject,
  IN extends Maybe<TypeOfShape<S>>,
  OUT extends Maybe<AssertsShape<S>>,
>(
  schema: yup.ObjectSchema<S, C, IN, OUT>,
  data: IN,
  path: string,
  messageJoiner: (messages: string[]) => string = DEFAULT_MESSAGE_JOINER,
):
  | { result: 'ok'; data: OUT }
  | { result: 'error'; errors: Record<string, string> } => {
  try {
    const castData = schema.validateSyncAt(path, data, { abortEarly: false })
    return { result: 'ok', data: castData }
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      return {
        result: 'error',
        errors: parseValidationError(error, data, messageJoiner),
      }
    }
    return { result: 'error', errors: { _error: 'Unknown error' } }
  }
}

export const validateFull = <
  S extends ObjectShape,
  C extends AnyObject,
  IN extends Maybe<TypeOfShape<S>>,
  OUT extends Maybe<AssertsShape<S>>,
>(
  schema: yup.ObjectSchema<S, C, IN, OUT>,
  data: IN,
  messageJoiner: (messages: string[]) => string = DEFAULT_MESSAGE_JOINER,
):
  | { result: 'ok'; data: OUT }
  | { result: 'error'; errors: Record<string, string> } => {
  try {
    const castData = schema.validateSync(data, { abortEarly: false })
    return { result: 'ok', data: castData }
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      return {
        result: 'error',
        errors: parseValidationError(error, data, messageJoiner),
      }
    }
    return { result: 'error', errors: { _error: 'Unknown error' } }
  }
}
