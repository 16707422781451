import { kebabCase } from 'lodash'
import * as React from 'react'
import { useFormContext } from 'react-hook-form'

import CustomerPill from '@/customers/components/CustomerPill/CustomerPill'

import { UseFiltersReturn } from '../hooks/useFilters'

interface Props {
  activeCustomerId: Optional<string>
  customer: UseFiltersReturn['customers'][number]
  onChangeCustomer: (value: string | undefined) => void
  testId?: string
}

const CustomerButton = ({
  activeCustomerId,
  customer,
  onChangeCustomer,
  testId,
}: Props): JSX.Element => {
  const form = useFormContext()

  const { isSelected, primary, secondary } = React.useMemo(() => {
    const isSelected = String(customer.value) === activeCustomerId
    return {
      isSelected,
      primary: isSelected
        ? customer.item.swatchPrimary
        : 'rgba(20, 0, 40, 0.1)',
      secondary: isSelected
        ? customer.item.swatchSecondary
        : 'rgba(0, 0, 0, 0.5)',
    }
  }, [
    activeCustomerId,
    customer.item.swatchPrimary,
    customer.item.swatchSecondary,
    customer.value,
  ])

  return (
    <button
      data-testid={kebabCase(testId)}
      type='button'
      key={customer.value}
      onClick={() => {
        const value = isSelected ? undefined : String(customer.value)
        onChangeCustomer(value)
        form.setValue('customerId', value)
        form.setValue('depotId', undefined)
      }}
    >
      <CustomerPill primary={primary} secondary={secondary}>
        {customer.label}
      </CustomerPill>
    </button>
  )
}

export default CustomerButton
