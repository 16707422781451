import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Select from '~shared/components/Input/Select'

import { NewDriverFormData } from '../NewDriverModal'

import { Option, isOption } from '~types'

interface Props {
  depots: Option[]
  testId?: string
}

const DepotDropdown = ({ depots, testId }: Props): JSX.Element => {
  const { t } = useTranslation()
  const form = useFormContext<NewDriverFormData>()

  return (
    <Controller
      control={form.control}
      name='depotId'
      defaultValue={null}
      render={({ onChange, onBlur, value }) => (
        <Select
          testId={testId}
          label={t('depot_dropdown.label')}
          error={form.errors.depotId}
          selectProps={{
            isClearable: true,
            placeholder: t('depot_dropdown.placeholder'),
            value: value ? depots.find((cd) => cd.value === value) : null,
            onBlur,
            onChange: (v) => onChange(isOption(v) ? v.value : null),
            menuPlacement: 'auto',
            options: depots,
          }}
        />
      )}
    />
  )
}

export default DepotDropdown
