import {
  GetUserByEmailQuery,
  useCreateDriverApplicationMutation,
  useCreateDriverMutation,
} from '@wise/graphql'
import {
  invariant,
  isDefined,
  isNonEmptyString,
  showToastNotification,
} from '@wise/utils'
import * as React from 'react'
import { ErrorOption } from 'react-hook-form'

import { useMode } from '~shared/hooks/useMode'
import { trackAnalyticsEvent } from '~shared/services/analytics/analytics'
import { Emitter, emitter } from '~shared/services/emitter'
import { useUser } from '~shared/services/firebase/auth/hooks'

import { BasePlatform } from '@/platforms/hooks/usePlatform'
import {
  formatCreateApplication,
  formatCreateDriver,
} from '@/subcontractors/parsers/subcontractor'

import { triggerErrorModal } from '../../GenericModal/GenericModal'
import { NewDriverFormData } from '../NewDriverModal'

interface OnSubmitOptions {
  platform: BasePlatform
  userData: Optional<GetUserByEmailQuery>
  canSetExternalId: boolean
  setApiValidationError: (error: string | null) => void
  onSuccess: () => void
  setError: (name: keyof NewDriverFormData, value: ErrorOption) => void
}

type OnSubmitFn = (fd: NewDriverFormData) => Promise<void>

interface OnSubmitHookReturn {
  submit: OnSubmitFn
  loading: boolean
}

const useOnSubmit = ({
  platform,
  userData,
  canSetExternalId,
  setApiValidationError,
  onSuccess,
  setError,
}: OnSubmitOptions): OnSubmitHookReturn => {
  const authUser = useUser()
  const mode = useMode()
  const [createDriver, { loading: createDriverLoading }] =
    useCreateDriverMutation()
  const [createApplication, { loading: createApplicationLoading }] =
    useCreateDriverApplicationMutation()

  const submit = React.useCallback<OnSubmitFn>(
    async (formData) => {
      setApiValidationError(null)
      try {
        let userId = userData?.user?.id
        let userEmail = userData?.user?.email

        // If we have no user ID, then we must create the driver first
        if (!isNonEmptyString(userId)) {
          const variables = formatCreateDriver({
            formData,
            userData,
          })
          const response = (await createDriver({ variables })).data
          if (!response) return setApiValidationError('No response from server')
          if ((response.createSCUser.userErrors?.length ?? 0) > 0) {
            const unhandled: string[] = []
            response.createSCUser.userErrors?.forEach((err) => {
              switch (err.__typename) {
                case 'UserInvalidEmail':
                  setError('email', { type: 'server', message: err.message })
                  break
                case 'UserInvalidFirstName':
                  setError('firstName', {
                    type: 'server',
                    message: err.message,
                  })
                  break
                case 'UserInvalidLastName':
                  setError('lastName', { type: 'server', message: err.message })
                  break
                case 'UserInvalidPhone':
                  setError('phoneNumber', {
                    type: 'server',
                    message: /phone numbers is invalid/i.test(err.message)
                      ? 'Phone number is invalid'
                      : err.message,
                  })
                  break
                default:
                  unhandled.push(err.message)
                  break
              }
            })

            return setApiValidationError(
              unhandled.length > 0 ? unhandled.join('\n') : null,
            )
          }

          userId = response.createSCUser.user?.id
          userEmail = response.createSCUser.user?.email
        }

        invariant(isNonEmptyString(userId), 'A user ID was not provided')
        invariant(isNonEmptyString(userEmail), 'A user email was not provided')

        // Now create the application!
        const variables = formatCreateApplication({
          canSetExternalId,
          formData,
          platform,
          userId,
        })
        const response = (await createApplication({ variables })).data
        if (!response) return setApiValidationError('No response from server')

        // If we got here, we are good to go!
        showToastNotification({
          type: 'success',
          description: 'An invite has been sent to this driver.',
        })

        const allGigsWereInactive =
          ((userData?.user?.gigs?.length ?? 0) > 0 &&
            userData?.user?.gigs?.every((gig) => gig?.status === 'INACTIVE')) ??
          false

        trackAnalyticsEvent('driver_invite_sent', {
          auth_user_id: authUser?.id ?? 'unknown',
          role: authUser?.roles?.find(isDefined)?.title ?? 'unknown',
          mode,
          platform_id: platform.id,
          platform_type: platform.type,
          location_id: formData.depotId,
          was_inactive: allGigsWereInactive,
        })
        emitter.emit(Emitter.RefreshDrivers)
        emitter.emit(Emitter.RefreshApplications)

        onSuccess()
      } catch (error) {
        triggerErrorModal({ error })
      }
    },
    [
      authUser?.id,
      authUser?.roles,
      canSetExternalId,
      createApplication,
      createDriver,
      mode,
      onSuccess,
      platform,
      setApiValidationError,
      setError,
      userData,
    ],
  )

  const loading = createDriverLoading || createApplicationLoading

  return { submit, loading }
}

export default useOnSubmit
