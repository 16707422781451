import cx from 'classnames'
import * as React from 'react'

import useTheme, { Theme } from '~shared/hooks/useTheme'

import Button from '../Button/Button'

interface Props {
  Icon?: React.ComponentType<{ className: string }>
  onClick?: JSX.IntrinsicElements['button']['onClick']
  disabled?: boolean
  className?: string
  type?: JSX.IntrinsicElements['button']['type']
  loading?: boolean
  containerClassName?: string
  theme?: Theme
  testId?: string
}

const AddButton: React.FC<Props> = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      disabled = false,
      Icon,
      className,
      type,
      loading,
      theme: definedTheme,
      testId,
      ...props
    },
    ref,
  ) => {
    const defaultTheme = useTheme()
    const theme = definedTheme ?? defaultTheme

    return (
      <Button
        {...props}
        ref={ref}
        testId={testId}
        type={type}
        disabled={disabled}
        loading={loading}
        className={cx(
          'ml-auto rounded-full border-2 border-transparent px-8 py-2 text-sm font-bold shadow-md',
          disabled
            ? 'cursor-not-allowed text-black/20'
            : 'cursor-pointer text-white',
          disabled
            ? 'bg-grey-darker'
            : theme === 'green'
            ? 'bg-green'
            : 'bg-purple',
          className,
        )}
      >
        {Icon ? <Icon className='mr-3 inline-block shrink-0' /> : null}
        {children}
      </Button>
    )
  },
)
AddButton.displayName = 'AddButton'

export default AddButton
