import Tooltip from '@tippyjs/react'

import CreateResourceMenu from '~shared/components/CreateResource/CreateResourceMenu'
import CreateCircle from '~shared/components/Icons/svg/create-circle.svg'
import { isClientSide } from '~shared/services/context'

import { usePlatform } from '@/platforms/hooks/usePlatform'

const CreateResource = () => {
  const platform = usePlatform()

  if (platform?.type !== 'network') return null

  return (
    <Tooltip
      content={<CreateResourceMenu platform={platform} />}
      placement='bottom'
      theme='light'
      animation='shift-away'
      maxWidth='none'
      arrow={false}
      trigger='click'
      interactive
      appendTo={isClientSide() ? document.body : 'parent'}
      popperOptions={{
        strategy: 'fixed',
        modifiers: [
          {
            name: 'preventOverflow',
            options: { altAxis: true, padding: 24 },
          },
        ],
      }}
    >
      <button
        data-testid='create-resource'
        className='flex h-16 w-12 items-center justify-center'
      >
        <CreateCircle className='h-8 w-8' />
      </button>
    </Tooltip>
  )
}

export default CreateResource
