import cx from 'classnames'
import { kebabCase } from 'lodash'
import * as React from 'react'
import ReactSelect, { OptionTypeBase, Styles } from 'react-select'

import Error from '../Form/Error'
import Label from '../Form/Label'

import { InputProps } from './Input'

interface Props extends InputProps {
  customStyle?: Partial<Styles<OptionTypeBase, false>>
  id?: string
  className?: string
  selectProps: React.ComponentProps<typeof ReactSelect>
  selectClassName?: string
  height?: string
  errorBorder?: boolean
  pending?: boolean
  testId?: string
  variant?: 'standard' | 'large'
}

const getStyles = (props: {
  errorBorder: boolean
  pending: boolean
  customStyle?: Partial<Styles<OptionTypeBase, false>>
  variant: 'standard' | 'large'
}): Partial<Styles<OptionTypeBase, false>> => ({
  control: (base, state) => ({
    ...base,
    backgroundColor: state.isDisabled
      ? 'rgba(202, 206, 213)'
      : props.pending === true
      ? '#ffefda'
      : 'white',
    height: '100%',
    borderRadius: '0.5rem',
    borderWidth: 2,
    borderColor: props.errorBorder
      ? '#BE0E0E !important'
      : state.isFocused
      ? '#66339957 !important'
      : '#CACED5 !important',
    boxShadow: state.isFocused ? '0 0 0 2px #66339927' : 'none',
  }),
  singleValue: (base) => ({
    ...base,
    width: '100%',
    fontWeight: props.variant === 'large' ? 'bold' : '',
    fontSize: props.variant === 'large' ? '17px' : '',
  }),
  menuList: (base) => ({
    ...base,
    margin: 2,
    padding: 0,
    borderRadius: 3,
    overflowX: 'hidden',
    overflowY: 'auto',
    fontSize: props.variant === 'large' ? '16px' : '',
  }),
  option: (base, state) => ({
    ...base,
    transition:
      'text-indent 100ms ease-out, background-color 100ms ease-out, border-color 100ms ease-out',
    backgroundColor: state.isSelected
      ? state.isFocused
        ? '#66339927'
        : '#66339917'
      : state.isFocused
      ? '#66339907'
      : 'transparent',
    color: state.isDisabled ? 'rgba(0, 0, 0, .2)' : 'black',
    borderLeft: state.isSelected
      ? '2px solid #663399'
      : '2px solid transparent',
    textIndent: state.isFocused ? 2 : 0,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    height: props.variant === 'large' ? '48px' : '',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  ...props.customStyle,
})

const SelectCreateCustomLabels = ({
  customStyle,
  id,
  error,
  label,
  className,
  selectProps,
  selectClassName,
  height,
  errorBorder = false,
  pending = false,
  testId,
  variant = 'standard',
}: Props): JSX.Element => {
  const styles = React.useMemo(
    () => getStyles({ errorBorder, pending, customStyle, variant }),
    [errorBorder, pending, customStyle, variant],
  )

  const errorMessage = React.useMemo(
    () => (typeof error === 'string' ? error : error?.message),
    [error],
  )

  return (
    <div className={cx('flex flex-col items-start', className)}>
      <Label htmlFor={id} hasError={Boolean(error)} testId={testId}>
        {label}
      </Label>
      <div data-testid={kebabCase(testId)} className='w-full'>
        <ReactSelect
          id={id}
          className={cx(
            'my-1 w-full font-light',
            height || variant === 'large' ? 'h-[48px]' : 'min-h-10',
            selectClassName,
          )}
          styles={styles}
          {...selectProps}
        />
        <Error testId={`validation-error-${errorMessage + '-' + '-' + label}`}>
          {errorMessage}
        </Error>
      </div>
    </div>
  )
}

export default React.memo(SelectCreateCustomLabels)
