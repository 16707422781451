import { ApolloError } from '@apollo/client'
import { isNonEmptyString } from '@wise/utils'
import get from 'lodash/get'

export const prettyPrintApolloError = (error: ApolloError): string => {
  const networkErrorMessage = get(error, 'networkError.result.errors.0.message')
  if (isNonEmptyString(networkErrorMessage)) return networkErrorMessage

  const graphQLError = get(error, 'graphQLErrors.0.message')
  if (isNonEmptyString(graphQLError)) return graphQLError

  return error.message
}
