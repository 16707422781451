import Tooltip from '@tippyjs/react'
import { djs, getInitials, now } from '@wise/utils'
import cx from 'classnames'
import * as React from 'react'

import CreateResource from '~shared/components/CreateResource/CreateResource'
import WarningIcon from '~shared/components/Icons/svg/exclamation-circle.svg'
import WLogo from '~shared/components/Icons/svg/w-logo.svg'
import Notifications from '~shared/components/Notifications/Notifications'
import { APP_ENV } from '~shared/config/app'
import { useMaintenanceContext } from '~shared/hooks/useMaintenanceMode'
import { isClientSide } from '~shared/services/context'
import { useProviderInfo, useUser } from '~shared/services/firebase/auth/hooks'

import FluidNav from './FluidNav'
import ProfileMenu from './ProfileMenu'
import styles from './SideNav.module.scss'

const TopNav = (): JSX.Element => {
  const user = useUser()
  const { config: maintenanceConfig } = useMaintenanceContext()
  const providerInfo = useProviderInfo()

  const avatar = React.useMemo(() => {
    return providerInfo?.avatarUrl ?? null
  }, [providerInfo?.avatarUrl])

  const upcomingMaintenance = React.useMemo(() => {
    if (!maintenanceConfig) return
    if (!maintenanceConfig.showUpcomingWarnings) return
    const from = maintenanceConfig.from ? djs(maintenanceConfig.from) : null
    const to = maintenanceConfig.to ? djs(maintenanceConfig.to) : null

    if (!from || !from.isValid()) return
    if (from.isBefore(now())) return

    return {
      from: from.format('dddd, D MMM YYYY [@] h:mma'),
      to: to && to.isValid() ? to.format('dddd, D MMM YYYY [@] h:mma') : null,
    }
  }, [maintenanceConfig])

  return (
    <nav
      className={cx(
        'z-40 flex min-h-[72px] w-full bg-white bg-gradient-to-b text-black',
        styles.nav,
      )}
    >
      <div className='mx-auto flex w-full min-w-[700px] max-w-[3100px] flex-row items-center'>
        <div className='mr-3 flex justify-center self-stretch'>
          <div
            className={cx(
              'relative flex h-full w-20 shrink-0 flex-col items-center justify-center bg-gradient-to-tr from-theme to-theme-light text-white',
            )}
          >
            <WLogo width='2.5rem' height='2.5rem' />
            {APP_ENV !== 'production' ? (
              <span className='-mt-1.5 text-center text-[8px] font-black text-white'>
                {String(APP_ENV).toUpperCase()}
              </span>
            ) : null}
          </div>
        </div>
        <FluidNav />
        <div className='ml-auto' />
        {upcomingMaintenance ? (
          <Tooltip
            content={
              <div className='flex max-w-500 flex-col p-6 text-lg'>
                <h1 className='mb-6 flex flex-row items-center text-3xl font-bold text-red-dark'>
                  <WarningIcon className='mr-3' />
                  Upcoming Maintenance
                </h1>
                <p>There is planned maintenance coming up soon.</p>
                <p className='mb-6 font-bold text-red-dark'>
                  The site will be unavailable during this period.
                </p>
                <div className='flex flex-row items-center gap-3'>
                  <p className='w-16 text-right text-lg font-black'>From:</p>
                  <div>
                    <p>{upcomingMaintenance.from}</p>
                  </div>
                </div>
                <div className='flex flex-row items-center gap-3'>
                  <p className='w-16 text-right text-lg font-black'>
                    {upcomingMaintenance.to ? 'To:' : ''}
                  </p>
                  <div>
                    <p>{upcomingMaintenance.to ?? 'Until further notice'}</p>
                  </div>
                </div>
              </div>
            }
            placement='bottom'
            theme='light'
            animation='shift-away'
            maxWidth='none'
            appendTo={isClientSide() ? document.body : 'parent'}
          >
            <div
              data-testid='maintenance-warning-hover'
              className='flex h-16 w-14 items-center justify-center'
            >
              <WarningIcon className='h-8 w-8 text-red-dark' />
            </div>
          </Tooltip>
        ) : null}
        <CreateResource />
        <Notifications />
        <Tooltip
          content={<ProfileMenu />}
          placement='bottom'
          theme='light'
          animation='shift-away'
          maxWidth='none'
          arrow={false}
          trigger='click'
          interactive
          appendTo={isClientSide() ? document.body : 'parent'}
          popperOptions={{
            strategy: 'fixed',
            modifiers: [
              {
                name: 'preventOverflow',
                options: { altAxis: true, padding: 24 },
              },
            ],
          }}
        >
          <button
            data-testid={'logout-change-password-button'}
            className='flex h-16 w-12 items-center justify-center'
          >
            <div
              className={cx(
                'flex h-8 w-8 items-center justify-center overflow-hidden rounded-full bg-gradient-to-tr from-theme-inverted to-theme-inverted-light text-base font-bold text-white',
              )}
            >
              {avatar ? (
                <img
                  src={avatar}
                  alt='Profile picture'
                  className='h-full w-full bg-cover object-cover'
                />
              ) : (
                getInitials(user?.firstName, user?.lastName ?? undefined)
              )}
            </div>
          </button>
        </Tooltip>
      </div>
      <div className='mr-4' />
    </nav>
  )
}

export default React.memo(TopNav)
