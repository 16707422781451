import { GetUserByEmailQuery, useGetUserByEmailLazyQuery } from '@wise/graphql'
import { isNonEmptyString } from '@wise/utils'
import * as React from 'react'
import { FieldError, useFormContext } from 'react-hook-form'
import { useDebouncedCallback } from 'use-debounce'
import * as yup from 'yup'

import Input from '~shared/components/Input/Input'

import { BasePlatform } from '@/platforms/hooks/usePlatform'

import { NewDriverFormData } from '../NewDriverModal'

export interface EmailFieldState {
  userData: Optional<GetUserByEmailQuery>
  canSetExternalId: boolean
  status:
    | 'loading' // Something is loading
    | 'no-user' // There is no user with that email
    | 'existing-user' // There is a user with that email
    | 'invalid-email' // The email is invalid (but not empty)
    | 'banned-email' // The user type is not allowed
    | 'initial' // No email has been entered yet
}
interface EmailState {
  email: string
  dirty: boolean
}

const emailStateReducer: React.Reducer<
  EmailState,
  { type: 'set-email'; email: string } | { type: 'unmark-as-dirty' }
> = (state, action) => {
  switch (action.type) {
    case 'set-email':
      return { email: action.email, dirty: true }
    case 'unmark-as-dirty':
      return { ...state, dirty: false }
  }
}

interface Props {
  error: FieldError | undefined
  platform: BasePlatform
  setEmailState: (state: EmailFieldState) => void
}

const EmailField = ({
  error: providedError,
  platform,
  setEmailState,
}: Props): JSX.Element => {
  const form = useFormContext<NewDriverFormData>()
  const [{ email, dirty }, dispatch] = React.useReducer(emailStateReducer, {
    email: '',
    dirty: false,
  })
  const [fetchUserByEmail, { data: userData, loading: fetchingUser }] =
    useGetUserByEmailLazyQuery({
      onCompleted: () => {
        dispatch({ type: 'unmark-as-dirty' })
      },
      onError: () => {
        dispatch({ type: 'unmark-as-dirty' })
      },
    })

  const emailIsValid = React.useMemo(() => {
    return yup.string().email().required().isValidSync(email)
  }, [email])

  const checkUser = React.useCallback(
    (email: Optional<string>) => {
      if (isNonEmptyString(email)) {
        const mainContractorId =
          platform.type === 'main-contractor' ? platform.id : undefined
        const networkId = platform.type === 'network' ? platform.id : undefined
        fetchUserByEmail({
          variables: { email, mainContractorId, networkId },
        })
      } else {
        dispatch({ type: 'unmark-as-dirty' })
      }
    },
    [fetchUserByEmail, platform.id, platform.type],
  )

  const debouncedFetchUserByEmail = useDebouncedCallback(checkUser, 750)

  const isNotSubcontractor = React.useMemo(() => {
    return Boolean(userData?.user && userData?.user.type !== 'SUBCONTRACTOR')
  }, [userData?.user])

  const status = React.useMemo<EmailFieldState['status']>(() => {
    if (fetchingUser || dirty) return 'loading'
    if (!isNonEmptyString(email)) return 'initial'
    if (isNotSubcontractor) return 'banned-email'
    if (!emailIsValid) return 'invalid-email'
    return userData?.user ? 'existing-user' : 'no-user'
  }, [
    dirty,
    email,
    emailIsValid,
    fetchingUser,
    isNotSubcontractor,
    userData?.user,
  ])

  const canSetExternalId = React.useMemo(() => {
    const isReady = status === 'existing-user' || status === 'no-user'
    if (!isReady) return false

    const hasExternalId = userData?.user?.externalDriverId
    const hasOnlyInactiveGigs = userData?.user?.gigs?.every(
      (gig) => gig?.status === 'INACTIVE',
    )

    return Boolean(!hasExternalId || hasOnlyInactiveGigs)
  }, [status, userData?.user?.externalDriverId, userData?.user?.gigs])

  React.useEffect(() => {
    if (dirty) {
      debouncedFetchUserByEmail(email)
    }
  }, [debouncedFetchUserByEmail, dirty, email])

  const error = React.useMemo<FieldError | undefined>(() => {
    if (status === 'loading' || status === 'initial') return

    if (providedError) return providedError
    if (isNonEmptyString(email) && isNotSubcontractor)
      return {
        type: 'custom',
        message: 'This email cannot be used to send a driver invite',
      }

    if (isNonEmptyString(email) && !emailIsValid && !dirty && !fetchingUser)
      return {
        type: 'custom',
        message: 'You must specify a valid email address',
      }
  }, [
    email,
    dirty,
    emailIsValid,
    fetchingUser,
    isNotSubcontractor,
    providedError,
    status,
  ])

  React.useEffect(() => {
    setEmailState({ canSetExternalId, userData, status })
  }, [canSetExternalId, setEmailState, status, userData])

  return (
    <Input
      ref={form.register}
      name='email'
      id='new-driver__email'
      type='email'
      label='Email Address'
      className='mb-3'
      value={email ?? ''}
      onChange={(e) =>
        dispatch({ type: 'set-email', email: e.currentTarget.value })
      }
      loading={status === 'loading'}
      error={error}
    />
  )
}

export default EmailField
