import {
  CreateDriverApplicationMutationVariables,
  CreateDriverMutationVariables,
  GetUserByEmailQuery,
  PlatformType,
} from '@wise/graphql'
import { formatDateAndTime } from '@wise/utils'

import { NewDriverFormData } from '~shared/components/Modal/preset/NewDriverModal/NewDriverModal'
import { leaveBreadcrumb } from '~shared/services/bugsnag/client'

import { BasePlatform } from '@/platforms/hooks/usePlatform'

interface FormatCreateDriverOptions {
  formData: NewDriverFormData
  userData?: Optional<GetUserByEmailQuery>
}

export const formatCreateDriver = ({
  formData,
  userData,
}: FormatCreateDriverOptions): CreateDriverMutationVariables => {
  const firstName = formData.extraInfoNeeded
    ? formData.firstName
    : userData?.user?.firstName
  const lastName = formData.extraInfoNeeded
    ? formData.lastName
    : userData?.user?.lastName
  const phone = formData.extraInfoNeeded
    ? formData.phoneNumber
    : userData?.user?.phone1

  if (!firstName || !lastName || !phone) {
    leaveBreadcrumb(
      'Driver data missing',
      { firstName, lastName, phone },
      'state',
    )
    throw new Error('The driver has data missing')
  }

  return {
    input: {
      email: formData.email,
      firstName,
      lastName,
      phone,
    },
  }
}

interface FormatCreateDriverApplicationOptions {
  formData: NewDriverFormData
  platform: BasePlatform
  userId: string
  canSetExternalId: boolean
}

const getPlatformType = (platform: BasePlatform): PlatformType => {
  switch (platform.type) {
    case 'main-contractor':
      return 'MAINCONTRACTOR'
    case 'network':
      return 'NETWORK'
  }
}

export const formatCreateApplication = ({
  formData,
  userId,
  platform,
  canSetExternalId,
}: FormatCreateDriverApplicationOptions): CreateDriverApplicationMutationVariables => ({
  input: {
    customerID: formData.customerId,
    locationID: formData.depotId,
    platformID: platform.id,
    platformType: getPlatformType(platform),
    externalDriverID: canSetExternalId ? formData.externalID : undefined,
    scID: userId,
    interviewDateTime:
      formData.hasInterview && formData.date && formData.time
        ? formatDateAndTime(formData.date, formData.time)
        : undefined,
    onboardingConfigID: formData.roleId,
  },
})
