import { djs, isNonEmptyString, randomString } from '@wise/utils'
import produce from 'immer'
import { merge } from 'lodash'
import * as React from 'react'
import { PartialDeep } from 'type-fest'
import { useDebouncedCallback } from 'use-debounce'
import * as yup from 'yup'

import { validateFull } from './company/validator'

export type InviteMCsState = {
  customerId: string | null
  depotId: string | null
  visitDate: string | null
  mainContractors: {
    id: string
    firstName: string
    lastName: string
    email: string
    companyName: string
    businessOwner: {
      externalId: Optional<string>
      configId: Optional<string>
      needsInterview: boolean
      interviewDateTime: {
        date: Optional<Date>
        time: Optional<string>
      } | null
    } | null
  }[]
}
type McState = InviteMCsState['mainContractors'][number]

const mcSchema = yup.object({
  companyName: yup
    .string()
    .trim()
    .required()
    .label('Company name')
    .min(2)
    .max(64),
  email: yup.string().trim().email().required().label('Email').min(4).max(64),
  firstName: yup.string().trim().required().label('First name').min(2).max(64),
  id: yup.string().trim().required(),
  lastName: yup.string().trim().required().label('Last name').min(2).max(64),
  businessOwner: yup
    .object({
      externalId: yup.string().trim().nullable().label('External ID'),
      configId: yup.string().trim().nullable().required().label('Role'),
      needsInterview: yup.boolean().required(),
      interviewDateTime: yup
        .object({
          date: yup.date().nullable().required().label('Date').label('Date'),
          time: yup.string().nullable().required().label('Time').label('Time'),
        })
        .nullable()
        .when('needsInterview', (val, schema) =>
          val ? schema.required() : schema,
        )
        .label('Intreview date and time'),
    })
    .nullable(),
})

export const inviteMcsSchema = (needVisitDate: boolean) =>
  yup.object({
    customerId: yup.string().trim().nullable().required().label('Customer'),
    depotId: yup.string().trim().nullable().required().label('Location'),
    visitDate: yup
      .string()
      .nullable()
      .label('Visit date')
      .test('not-in-far-past', '${label} cannot be in the past', (value) => {
        if (!value) return true
        const d = djs(value)
        return d.isAfter(djs().subtract(1, 'day'), 'day')
      })
      .test(
        'not-in-far-future',
        '${label} is too far in the future',
        (value) => {
          if (!value) return true
          const d = djs(value)
          return d.isBefore(djs().add(1, 'year'), 'day')
        },
      )
      .test('is-required', '${label} is a required field', (value) =>
        needVisitDate && !isNonEmptyString(value) ? false : true,
      ),
    mainContractors: yup
      .array()
      .of(mcSchema)
      .min(1, 'At least one main contractor is required')
      .max(100, 'Maximum 100 main contractors are allowed')
      .required()
      .label('Main contractors'),
  })

const getBlankMainContractor =
  (): InviteMCsState['mainContractors'][number] => ({
    companyName: '',
    email: '',
    firstName: '',
    id: randomString(),
    lastName: '',
    businessOwner: null,
  })

type Action =
  | { type: 'set-customer-id'; value: InviteMCsState['customerId'] }
  | { type: 'set-depot-id'; value: InviteMCsState['depotId'] }
  | { type: 'set-visit-date'; value: InviteMCsState['visitDate'] }
  | { type: 'add-main-contractor' }
  | { type: 'remove-main-contractor'; id: McState['id'] }
  | {
      type: 'set-main-contractor-first-name'
      id: McState['id']
      value: McState['firstName']
    }
  | {
      type: 'set-main-contractor-last-name'
      id: McState['id']
      value: McState['lastName']
    }
  | {
      type: 'set-main-contractor-email'
      id: McState['id']
      value: McState['email']
    }
  | {
      type: 'set-main-contractor-company-name'
      id: McState['id']
      value: McState['companyName']
    }
  | {
      type: 'set-main-contractor-business-owner'
      id: McState['id']
      value: Mandatory<McState['businessOwner']>
    }
  | {
      type: 'update-main-contractor-business-owner'
      id: McState['id']
      value: PartialDeep<Mandatory<McState['businessOwner']>>
    }
  | {
      type: 'unset-main-contractor-business-owner'
      id: McState['id']
    }
  | { type: 'reset' }

const reducer: React.Reducer<InviteMCsState, Action> = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'set-customer-id':
        if (action.value === draft.customerId) break
        draft.customerId = action.value
        draft.depotId = null
        break
      case 'set-depot-id':
        draft.depotId = action.value
        break
      case 'set-visit-date':
        draft.visitDate = action.value || null
        break
      case 'add-main-contractor':
        draft.mainContractors.push(getBlankMainContractor())
        break
      case 'remove-main-contractor': {
        const ix = draft.mainContractors.findIndex((mc) => mc.id === action.id)
        if (ix === -1) return
        draft.mainContractors.splice(ix, 1)
        break
      }
      case 'set-main-contractor-company-name': {
        const mc = draft.mainContractors.find((mc) => mc.id === action.id)
        if (!mc) return
        mc.companyName = action.value
        break
      }
      case 'set-main-contractor-first-name': {
        const mc = draft.mainContractors.find((mc) => mc.id === action.id)
        if (!mc) return
        mc.firstName = action.value
        break
      }
      case 'set-main-contractor-last-name': {
        const mc = draft.mainContractors.find((mc) => mc.id === action.id)
        if (!mc) return
        mc.lastName = action.value
        break
      }
      case 'set-main-contractor-email': {
        const mc = draft.mainContractors.find((mc) => mc.id === action.id)
        if (!mc) return
        mc.email = action.value
        break
      }
      case 'set-main-contractor-business-owner': {
        const mc = draft.mainContractors.find((mc) => mc.id === action.id)
        if (!mc) return
        mc.businessOwner = action.value
        break
      }
      case 'update-main-contractor-business-owner': {
        const mc = draft.mainContractors.find((mc) => mc.id === action.id)
        if (!mc || mc.businessOwner === null) return
        mc.businessOwner = merge({}, mc.businessOwner, action.value)
        break
      }
      case 'unset-main-contractor-business-owner': {
        const mc = draft.mainContractors.find((mc) => mc.id === action.id)
        if (!mc) return
        mc.businessOwner = null
        break
      }
      case 'reset':
        return getBlankState()
    }
  })

const getBlankState = (): InviteMCsState => ({
  customerId: null,
  depotId: null,
  visitDate: null,
  mainContractors: [getBlankMainContractor()],
})

interface InviteMCsStateProps {
  needVisitDate: boolean
}

export const useInviteMCsState = ({ needVisitDate }: InviteMCsStateProps) => {
  const [state, dispatch] = React.useReducer(reducer, null, getBlankState)
  const [errors, setErrors] = React.useState<Record<string, string>>({})

  const validate = React.useCallback(() => {
    const outcome = validateFull(inviteMcsSchema(needVisitDate), state)
    setErrors(outcome.result === 'ok' ? {} : outcome.errors)
    return outcome
  }, [needVisitDate, state])

  const partialValidate = React.useCallback(
    (state: InviteMCsState) => {
      const errorKeys = Object.keys(errors)
      if (errorKeys.length === 0) return
      const outcome = validateFull(inviteMcsSchema(needVisitDate), state)
      if (outcome.result === 'ok') return setErrors({})
      const nextErrors = { ...errors }
      errorKeys.forEach((errorKey) => {
        if (outcome.errors[errorKey] === undefined) delete nextErrors[errorKey]
        if (errors[errorKey] && outcome.errors[errorKey])
          nextErrors[errorKey] = outcome.errors[errorKey]
      })
      return setErrors(nextErrors)
    },
    [needVisitDate, errors],
  )

  const debouncedPartialValidate = useDebouncedCallback(partialValidate, 100)

  React.useEffect(() => {
    debouncedPartialValidate(state)
  }, [debouncedPartialValidate, state])

  const resetErrors = React.useCallback(() => setErrors({}), [])

  return { state, dispatch, validate, errors, resetErrors }
}
