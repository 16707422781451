import Tooltip from '@tippyjs/react'
import { dateToDayFormat, now } from '@wise/utils'
import cx from 'classnames'
import { kebabCase } from 'lodash'
import * as React from 'react'
import { CaptionLayout, Matcher } from 'react-day-picker'
import { twMerge } from 'tailwind-merge'

import Calendar from '~shared/components/Icons/svg/calendar.svg'

import ClickInterceptor from '../ClickInterceptor/ClickInterceptor'
import Error from '../Form/Error'
import Label from '../Form/Label'

import DayPicker from './DayPicker/DayPicker'
import { InputProps } from './Input'
import styles from './Input.module.scss'

interface Props extends InputProps {
  name?: string
  onDayChange?: (day: Date | undefined) => void
  date?: Date
  placement?: React.ComponentProps<typeof Tooltip>['placement']
  testId?: string
  disableBeforeDate?: Date
  disableAfterDate?: Date
  captionLayout?: CaptionLayout
  disabledDates?: Matcher[]
  isInputDisabled?: boolean
  rightIcon?: React.ReactElement
}

const DayInput = React.forwardRef<
  HTMLInputElement,
  Props & JSX.IntrinsicElements['input']
>(
  (
    {
      testId: rawTestId,
      className,
      date,
      onDayChange,
      error,
      label,
      disableBeforeDate,
      disableAfterDate,
      placement = 'right-end',
      placeholder,
      captionLayout = 'buttons',
      disabledDates = [],
      isInputDisabled = false,
      rightIcon,
      ...props
    },
    ref,
  ): JSX.Element => {
    const { testId, errorTestId } = React.useMemo(() => {
      const raw = rawTestId || props.id || undefined
      if (!raw) return { testId: undefined, errorTestId: undefined }
      return { testId: kebabCase(raw), errorTestId: `${kebabCase(raw)}-error` }
    }, [props.id, rawTestId])

    const [open, setOpen] = React.useState(false)

    const handleDayClick = React.useCallback(
      (d: Date | undefined) => {
        onDayChange?.(d)
        setOpen(false)
      },
      [onDayChange],
    )

    const today = React.useMemo(() => now().toDate(), [])

    const errorMessage = React.useMemo(
      () => (typeof error === 'string' ? error : error?.message),
      [error],
    )

    return (
      <div className={twMerge(isInputDisabled && 'pointer-events-none')}>
        <input
          data-testid='day-input-field'
          {...props}
          className='hidden'
          ref={ref}
          type='date'
        />
        {open ? (
          <ClickInterceptor
            className='z-10 bg-purple-dark/20'
            onIntercept={() => setOpen(false)}
          />
        ) : null}
        <div
          className={cx('flex w-full flex-col items-start', className)}
          data-testid={kebabCase(testId)}
        >
          <Label
            onClick={() => setOpen(!open)}
            className='select-none'
            hasError={Boolean(error)}
            testId={testId}
          >
            {label}
          </Label>
          <Tooltip
            visible={open}
            interactive={true}
            theme='light'
            content={
              <DayPicker
                captionLayout={captionLayout}
                fromDate={disableBeforeDate ?? today}
                toDate={disableAfterDate}
                disabled={[
                  {
                    before: disableBeforeDate ?? today,
                    after: disableAfterDate,
                  },
                  ...disabledDates,
                ]}
                givenMonth={disableBeforeDate}
                className='z-20'
                onDayClick={handleDayClick}
                onClear={() => handleDayClick(undefined)}
                date={date}
                open={open}
                testId={`calender-${testId}`}
              />
            }
            placement={placement}
          >
            <p
              tabIndex={0}
              onFocus={() => setOpen(true)}
              className={twMerge(
                'my-1 flex w-full flex-row items-center rounded-lg border-2 border-grey-dark bg-transparent  px-2 py-2 text-base font-light text-black outline-none focus:border-purple-300 focus:text-purple-900',
                isInputDisabled && 'bg-[#caced5]',
                open && 'border-purple-300 text-purple-900',
                open ? styles.inputWithFocus : styles.input,
              )}
            >
              {!rightIcon && <Calendar className='mr-2 text-grey-darker' />}
              {date ? (
                dateToDayFormat(date)
              ) : (
                <span className='text-black/50'>{placeholder}</span>
              )}
              {rightIcon}
            </p>
          </Tooltip>
          <Error testId={errorTestId}>{errorMessage}</Error>
        </div>
      </div>
    )
  },
)

DayInput.displayName = 'DayInput'

export default DayInput
