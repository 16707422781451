import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import TickIcon from '~shared/components/Icons/svg/tick.svg'
import Input from '~shared/components/Input/Input'
import Select from '~shared/components/Input/Select'
import Loading from '~shared/components/Loading/Loading'
import { useMode } from '~shared/hooks/useMode'
import useTheme from '~shared/hooks/useTheme'

import { BasePlatform } from '@/platforms/hooks/usePlatform'
import { newDriverRequiredResolver } from '@/subcontractors/validation/new-driver'

import AddButton from '../../../Buttons/AddButton/AddButton'
import Error from '../../../Form/Error'
import Label from '../../../Form/Label'
import DayInput from '../../../Input/DayInput'
import TimePicker from '../../../Input/TimePicker/TimePicker'
import ModalHeader from '../../../UI/ModalHeader'
import { ModalProps, removeModal } from '../../useModal'

import CustomerButton from './components/CustomerButton'
import DepotDropdown from './components/DepotDropdown'
import EmailField, { EmailFieldState } from './components/EmailField'
import useFilters from './hooks/useFilters'
import useOnSubmit from './hooks/useOnSubmit'

import { isOption } from '~types'

interface Props {
  platform: BasePlatform
}

export interface NewDriverFormData {
  email: string
  firstName: Optional<string>
  lastName: Optional<string>
  phoneNumber: Optional<string>
  customerId: string
  date: Optional<Date>
  time: Optional<string>
  depotId: string
  extraInfoNeeded: Optional<boolean>
  hasInterview: Optional<boolean>
  externalID: Optional<string>
  roleId: string
}

const NewDriverModal = ({
  id: modalId,
  platform,
}: ModalProps & Props): JSX.Element => {
  const mode = useMode()
  const theme = useTheme()

  const form = useForm<NewDriverFormData>({
    resolver: newDriverRequiredResolver,
  })

  const [apiError, setApiError] = React.useState<string | null>(null)
  const [customerId, setCustomerId] = React.useState<Optional<string>>()
  const [roleId, setRoleId] = React.useState<Optional<string>>()
  const [emailState, setEmailState] = React.useState<EmailFieldState>({
    canSetExternalId: false,
    status: 'loading',
    userData: null,
  })

  const [fieldVisibilityStatus, setFieldVisibilityStatus] = React.useState<
    'none' | 'all' | 'exclude-external-id'
  >('none')

  React.useEffect(() => {
    switch (emailState.status) {
      case 'no-user':
        return void setFieldVisibilityStatus(
          emailState.canSetExternalId ? 'all' : 'exclude-external-id',
        )
      case 'existing-user':
        return void setFieldVisibilityStatus('none')
    }
  }, [emailState.canSetExternalId, emailState.status])

  const {
    customers,
    depots,
    roles,
    hasInterview,
    loading: filtersLoading,
  } = useFilters({
    customerId,
    roleId,
    platform,
  })

  const { submit, loading: mutationLoading } = useOnSubmit({
    platform,
    canSetExternalId: emailState.canSetExternalId,
    setApiValidationError: setApiError,
    userData: emailState.userData,
    onSuccess: React.useCallback(() => removeModal(modalId), [modalId]),
    setError: form.setError,
  })

  return (
    <div className='max-w-500 space-y-3 rounded-xl bg-white p-12 shadow-md'>
      <ModalHeader>Add a new driver.</ModalHeader>
      <p className='mb-6 text-sm font-light'>
        Enter the details for the subcontractor and select the customer.
      </p>
      {filtersLoading ? (
        <div className='flex items-center justify-center p-3'>
          <Loading colour={theme} />
        </div>
      ) : customers.length === 0 ? (
        <div className='mt-2 w-full rounded-lg border border-red/10 bg-red/10 p-3 text-xs font-bold text-red-dark'>
          <h3 className='mb-2 text-lg font-bold'>No roles...</h3>
          <p>
            You must have at least one active role setup before inviting drivers
            into the platform.
            <br />
            <br />
            Please get in touch with your Wise Account Manager for further
            assistance.
          </p>
        </div>
      ) : (
        <FormProvider {...form}>
          <motion.form
            onSubmit={form.handleSubmit(submit)}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <input
              data-testid='extra-info-needed-input'
              ref={form.register({ valueAsNumber: true })}
              type='hidden'
              name='extraInfoNeeded'
              value={emailState.status === 'no-user' ? 1 : 0}
            />
            <input
              data-testid='has-interview-input'
              ref={form.register({ valueAsNumber: true })}
              type='hidden'
              name='hasInterview'
              value={hasInterview ? 1 : 0}
            />
            <EmailField
              platform={platform}
              setEmailState={setEmailState}
              error={form.errors.email}
            />
            <AnimatePresence>
              {emailState.status === 'existing-user' && mode === 'WAP' ? (
                <motion.p
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  exit={{ height: 0 }}
                  className='-mt-2 mb-2 flex flex-row items-center overflow-hidden text-xs text-green'
                >
                  <TickIcon className='mr-2' /> We already have this drivers
                  contact information.
                </motion.p>
              ) : null}
            </AnimatePresence>
            <AnimatePresence>
              {fieldVisibilityStatus !== 'none' ? (
                <motion.div
                  initial={{ height: '0', opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className='overflow-hidden'
                >
                  <div className='grid grid-cols-2 gap-3 overflow-hidden'>
                    <Input
                      ref={form.register}
                      id='new-driver__first-name'
                      type='text'
                      label='First name'
                      name='firstName'
                      error={form.errors.firstName}
                    />
                    <Input
                      ref={form.register}
                      id='new-driver__last-name'
                      type='text'
                      label='Last name'
                      name='lastName'
                      error={form.errors.lastName}
                    />
                    <Input
                      ref={form.register}
                      id='new-driver__phone-number'
                      type='text'
                      label='Phone number'
                      className='col-span-full'
                      name='phoneNumber'
                      error={form.errors.phoneNumber}
                    />
                  </div>
                </motion.div>
              ) : null}
            </AnimatePresence>
            <AnimatePresence>
              {fieldVisibilityStatus === 'all' ? (
                <motion.div
                  initial={{ height: '0', opacity: 0 }}
                  animate={{ height: 'auto', opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  className='mt-3 overflow-hidden'
                >
                  <Input
                    ref={form.register}
                    id='new-driver__external-ID'
                    type='text'
                    name='externalID'
                    label={
                      <p>
                        External ID number{' '}
                        <span className='text-center text-xs text-grey-dark'>
                          (optional)
                        </span>
                      </p>
                    }
                    error={form.errors.externalID}
                  />
                </motion.div>
              ) : null}
            </AnimatePresence>
            <div className='mt-3'>
              <Label className='mb-1'>Customer</Label>
              <input
                data-testid='customer-id-input-new-driver-modal'
                ref={form.register}
                type='hidden'
                name='customerId'
              />
              <div className='flex w-full flex-wrap'>
                {customers.map((customer) => (
                  <CustomerButton
                    testId={`new-driver-${customer}-button`}
                    key={customer.value}
                    activeCustomerId={customerId}
                    customer={customer}
                    onChangeCustomer={(newCustomerId) => {
                      if (newCustomerId !== customerId || !newCustomerId) {
                        setRoleId(undefined)
                      }
                      setCustomerId(newCustomerId)
                    }}
                  />
                ))}
              </div>
              <Error>{form.errors.customerId?.message}</Error>
            </div>
            <div className='mt-3'>
              <Label className='mb-1'>Role</Label>
              <input
                data-testid='role-id-input-new-driver-modal'
                ref={form.register}
                type='hidden'
                name='roleId'
              />
              <Select
                selectProps={{
                  isDisabled: !customerId,
                  options: roles,
                  value: roles.find((role) => role.value === roleId) || null,
                  onChange: (option) => {
                    const value = isOption(option)
                      ? String(option.value)
                      : undefined
                    setRoleId(value)
                    form.setValue('roleId', value)
                    form.clearErrors('roleId')
                  },
                }}
              />
              <Error>{form.errors.roleId?.message}</Error>
            </div>
            <AnimatePresence>
              {hasInterview ? (
                <motion.div
                  initial={{ height: '0', opacity: 0, overflow: 'hidden' }}
                  animate={{
                    height: 'auto',
                    opacity: 1,
                    overflow: 'unset',
                  }}
                  exit={{ height: 0, opacity: 0, overflow: 'hidden' }}
                >
                  <div className='pt-6'>
                    <ModalHeader>Interview details.</ModalHeader>
                    <p className='mb-3 text-sm font-light'>
                      Enter the interview details for the subcontractor.
                    </p>
                    <div className='mb-3 grid grid-cols-2 gap-3'>
                      <Controller
                        control={form.control}
                        name='date'
                        defaultValue={null}
                        render={({ onChange, onBlur, value }) => (
                          <DayInput
                            testId='new-driver__date'
                            id='new-driver__date'
                            label='Date'
                            onBlur={onBlur}
                            onDayChange={onChange}
                            date={value}
                            error={form.errors.date}
                          />
                        )}
                      />
                      <Controller
                        control={form.control}
                        name='time'
                        defaultValue={null}
                        render={({ onChange, onBlur, value }) => (
                          <TimePicker
                            testId='new-driver__time'
                            label='Time'
                            minuteGap={5}
                            startFrom={540}
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            placeholder=''
                            error={form.errors.time}
                          />
                        )}
                      />
                    </div>
                    <DepotDropdown
                      testId='new-driver__depot-dropdown'
                      depots={depots}
                    />
                  </div>
                </motion.div>
              ) : null}
            </AnimatePresence>
            <AnimatePresence>
              {Boolean(customerId) && Boolean(roleId) && !hasInterview ? (
                <motion.div
                  initial={{ height: '0', opacity: 0, overflow: 'hidden' }}
                  animate={{
                    height: 'auto',
                    opacity: 1,
                    overflow: 'unset',
                  }}
                  exit={{ height: 0, opacity: 0, overflow: 'hidden' }}
                >
                  <div className='pt-3'>
                    <DepotDropdown
                      testId='new-driver__depot-dropdown'
                      depots={depots}
                    />
                  </div>
                </motion.div>
              ) : null}
            </AnimatePresence>
            <Error>{apiError}</Error>
            <AddButton
              testId='new-driver__add-button'
              disabled={
                emailState.status === 'loading' ||
                emailState.status === 'invalid-email' ||
                emailState.status === 'banned-email' ||
                !customerId ||
                mutationLoading
              }
              type='submit'
              theme='green'
              loading={mutationLoading}
              className='mt-8 mr-auto py-2 px-12 text-lg'
            >
              Invite driver.
            </AddButton>
          </motion.form>
        </FormProvider>
      )}
    </div>
  )
}

export default NewDriverModal
