import { kebabCase } from 'lodash'
import * as React from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
  paths: string[]
  noMargins?: boolean
}

const PathTitle = ({ paths, noMargins = false }: Props) => {
  return (
    <div
      className={twMerge(
        'flex h-[50px] flex-row items-center gap-3 text-xl font-light',
        !noMargins && 'mb-6',
      )}
    >
      {paths.map((path, ix) => {
        const isFirst = ix === 0
        const isLast = ix === paths.length - 1
        return (
          <React.Fragment key={ix}>
            {!isFirst && (
              <div
                className={twMerge(
                  'h-[18px] w-[1px] -skew-x-12',
                  isLast ? 'bg-theme' : 'bg-black/25',
                )}
              />
            )}
            <p
              className={twMerge(
                isLast
                  ? 'bg-gradient-to-r from-theme to-theme-light bg-clip-text font-bold text-transparent'
                  : 'text-black/75',
              )}
              data-testid={`${kebabCase(path)}-page-title-path`}
            >
              {path}
              {isLast && !path.endsWith('.') ? '.' : ''}
            </p>
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default PathTitle
