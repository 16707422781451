import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import ParcelIcon from '~shared/components/Icons/svg/main-contractor.svg'
import UserIcon from '~shared/components/Icons/svg/user.svg'
import CreatePartnerModal from '~shared/components/Modal/preset/CreatePartnerModal/CreatePartnerModal'
import NewDriverModal from '~shared/components/Modal/preset/NewDriverModal/NewDriverModal'
import { pushModal } from '~shared/components/Modal/useModal'
import { createComponent } from '~shared/utils/createComponent'

import { NetworkPlatform } from '@/platforms/hooks/usePlatform'

type MenuItemProps = {
  testId: string
  icon: React.ElementType
  title: string
  description: string
  onClick: () => void
}

const MenuItem = ({
  testId,
  icon: Icon,
  title,
  description,
  onClick,
}: MenuItemProps) => {
  return (
    <button
      data-testid={testId}
      onClick={onClick}
      className='group flex cursor-pointer flex-row items-stretch gap-3 rounded-[8px] bg-gradient-to-tr p-3 hover:bg-grey-100 focus:bg-grey-100'
    >
      <div
        className={twMerge(
          'flex h-10 w-10 flex-shrink-0 items-center justify-center self-center rounded-md bg-black/5 transition-colors group-hover:bg-white group-focus:bg-white',
          'text-black/25',
          'group-hover:text-theme-light group-focus:text-theme-light',
          'group-hover:shadow-glow group-focus:shadow-glow',
        )}
      >
        <Icon
          className={twMerge('group-hover:fill-theme-light', 'fill-black/25')}
        />
      </div>
      <div className='flex flex-col items-start'>
        <div>
          <p
            className={twMerge(
              'inline-block text-base font-bold',
              'group-hover:bg-gradient-to-r group-hover:from-theme group-hover:to-theme-light group-hover:bg-clip-text group-hover:text-transparent',
              'group-focus:bg-gradient-to-r group-focus:from-theme group-focus:to-theme-light group-focus:bg-clip-text group-focus:text-transparent',
            )}
          >
            {title}
          </p>
        </div>
        <p className='max-w-400 text-xs font-light'>{description}</p>
      </div>
    </button>
  )
}

type Props = {
  platform: NetworkPlatform
}

const CreateResourceMenu = ({ platform }: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div className='relative flex w-screen max-w-[450px] flex-col gap-3 rounded-lg bg-white p-3 shadow-glow'>
      <MenuItem
        key='create-driver'
        description={t('side_nav.actions.create_resource.driver_description')}
        icon={UserIcon}
        onClick={() =>
          pushModal({
            id: 'create-driver-modal',
            component: createComponent(NewDriverModal, {
              platform,
            }),
          })
        }
        testId='create-driver'
        title={t('side_nav.actions.create_resource.driver')}
      />
      <MenuItem
        key='create-partner'
        description={t('side_nav.actions.create_resource.partner_description')}
        icon={ParcelIcon}
        onClick={() =>
          pushModal({
            id: 'create-partner-modal',
            component: createComponent(CreatePartnerModal, {
              platformId: platform.id,
            }),
          })
        }
        testId='create-partner'
        title={t('side_nav.actions.create_resource.partner')}
      />
    </div>
  )
}

export default React.memo(CreateResourceMenu)
