import { ApolloError } from '@apollo/client'
import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import { prettyPrintApolloError } from '~shared/services/apollo/error'

interface Props {
  className?: string
  error: Optional<ApolloError>
  errorMessage?: string
}

const ApiErrorBlock = ({
  className,
  error,
  errorMessage,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <AnimatePresence>
      {error ? (
        <motion.div
          className={cx('overflow-hidden', className)}
          initial={{ height: '0', opacity: 0 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: '0', opacity: 0 }}
        >
          <div className='rounded-sm border-l-4 border-red bg-red-light/30 py-3 px-4 text-sm font-light text-red-dark'>
            <h3 className='text-lg font-bold'>
              {t('api_error_block.heading')}
            </h3>
            <p>{errorMessage ? errorMessage : prettyPrintApolloError(error)}</p>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

export default ApiErrorBlock
