import {
  GetInviteDriverLocationsAndCustomersForMainContractorQuery,
  useGetInviteDriverLocationsAndCustomersForMainContractorQuery,
  useGetInviteDriverLocationsAndCustomersForNetworkQuery,
} from '@wise/graphql'
import { isDefined, toOptionList } from '@wise/utils'
import { uniqBy } from 'lodash'
import * as React from 'react'

import { BasePlatform } from '@/platforms/hooks/usePlatform'

import { Option } from '~types'

interface UseFiltersOptions {
  platform: BasePlatform
  customerId: Optional<string>
  roleId: Optional<string>
}

type ConfigType = Mandatory<
  Mandatory<
    GetInviteDriverLocationsAndCustomersForMainContractorQuery['onboardingConfigurations']
  >[number]
>
type CustomerType = Mandatory<ConfigType['customer']>

export interface UseFiltersReturn {
  customers: Option<{ item: CustomerType }>[]
  roles: Option<{ item: ConfigType }>[]
  depots: Option[]
  hasInterview: boolean
  loading: boolean
}

const useFilters = ({
  customerId,
  roleId,
  platform,
}: UseFiltersOptions): UseFiltersReturn => {
  const mcQuery = useGetInviteDriverLocationsAndCustomersForMainContractorQuery(
    platform.type === 'main-contractor'
      ? { variables: { id: platform.id, mainContractorId: platform.id } }
      : { skip: true },
  )

  const networkQuery = useGetInviteDriverLocationsAndCustomersForNetworkQuery(
    platform.type === 'network'
      ? { variables: { networkId: platform.id } }
      : { skip: true },
  )

  const locations = React.useMemo(() => {
    switch (platform.type) {
      case 'main-contractor':
        return mcQuery.data?.mainContractor?.locations ?? []
      case 'network': {
        const network = networkQuery.data?.getNetwork
        if (network?.__typename !== 'Network') return []
        return network.customers?.flatMap((c) => c?.locations) ?? []
      }
    }
  }, [
    mcQuery.data?.mainContractor?.locations,
    networkQuery.data?.getNetwork,
    platform.type,
  ])

  const activeConfigs = React.useMemo(() => {
    switch (platform.type) {
      case 'main-contractor': {
        return (
          mcQuery.data?.onboardingConfigurations
            ?.filter((obc) => {
              if (!obc?.active) return false
              const locations = mcQuery.data?.mainContractor?.locations?.filter(
                (loc) => loc?.customer?.id === obc.customer?.id,
              )
              return locations && locations.length > 0
            })
            .filter(isDefined) ?? []
        )
      }
      case 'network': {
        return (
          networkQuery.data?.onboardingConfigurations
            ?.filter((obc) => {
              if (!obc?.active) return false
              const network = networkQuery.data?.getNetwork
              if (network?.__typename !== 'Network') return false
              const locations = network.customers
                ?.filter((c) => c?.id === obc.customer?.id)
                .flatMap((c) => c?.locations)
              return locations && locations.length > 0
            })
            .filter(isDefined) ?? []
        )
      }
    }
  }, [
    mcQuery.data?.mainContractor?.locations,
    mcQuery.data?.onboardingConfigurations,
    networkQuery.data?.getNetwork,
    networkQuery.data?.onboardingConfigurations,
    platform.type,
  ])

  const customers = React.useMemo(() => {
    return toOptionList({
      items:
        uniqBy(
          activeConfigs.map((obc) => obc.customer),
          'id',
        ) ?? [],
      value: 'id',
      label: 'name',
    })
  }, [activeConfigs])

  const selectedConfig = React.useMemo(
    () =>
      activeConfigs.find(
        (obc) => obc.customer?.id === customerId && obc.id === roleId,
      ),
    [activeConfigs, customerId, roleId],
  )

  const hasInterview = React.useMemo(
    () => selectedConfig?.interviewEnabled ?? false,
    [selectedConfig?.interviewEnabled],
  )

  const depots = React.useMemo(() => {
    if (!customerId) return []
    return toOptionList({
      items: locations.filter((loc) => loc?.customer?.id === customerId),
      value: 'id',
      label: 'name',
    })
  }, [customerId, locations])

  const roles = React.useMemo(() => {
    if (!customerId) return []
    const configsForCustomer = activeConfigs.filter(
      (config) => config?.customer?.id === customerId && config.active,
    )
    return toOptionList({
      items: configsForCustomer,
      value: 'id',
      label: 'name',
    })
  }, [activeConfigs, customerId])

  const loading = React.useMemo(() => {
    switch (platform.type) {
      case 'main-contractor':
        return mcQuery.loading
      case 'network':
        return networkQuery.loading
    }
  }, [mcQuery.loading, networkQuery.loading, platform.type])

  return {
    customers,
    depots,
    hasInterview,
    loading,
    roles,
  }
}

export default useFilters
