import cx from 'classnames'
import { kebabCase } from 'lodash'

interface Props {
  className?: string
  primary?: Optional<string>
  secondary?: Optional<string>
  variant?: 'default' | 'small' | 'large'
  testId?: string
}

const CustomerPill: React.FC<Props> = ({
  children,
  className,
  primary = '#c9c9c9',
  secondary = 'white',
  variant = 'default',
  testId,
}) => (
  <div
    className={cx(
      'inline-flex items-center break-words rounded-full',
      variant === 'default' && 'm-0.5 h-7 px-4 text-sm font-bold shadow-sm',
      variant === 'small' && 'm-0.5 px-2 py-1 text-xs font-light shadow-sm',
      variant === 'large' && 'm-1 px-6 py-1.5 text-lg font-bold shadow-md',
      className,
    )}
    style={{
      backgroundColor: primary ?? '#c9c9c9',
      color: secondary ?? 'white',
    }}
    data-testid={testId ? kebabCase(testId) : undefined}
  >
    {children}
  </div>
)

export default CustomerPill
