import { now } from '@wise/utils'
import cx from 'classnames'
import { kebabCase } from 'lodash'
import * as React from 'react'
import { DayPickerBase, DayPicker as ReactDayPicker } from 'react-day-picker'
import { useTranslation } from 'react-i18next'

import Button from '../../Buttons/Button/Button'

interface Props extends DayPickerBase {
  date?: Date
  onClear?: () => void
  open?: boolean
  testId?: string
  givenMonth?: Date
}

const DayPicker = ({
  testId,
  date,
  onClear,
  className,
  open = true,
  ...props
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const today = React.useMemo(() => now().toDate(), [])

  return (
    <div
      className={cx('flex flex-col', className)}
      data-testid={kebabCase(testId)}
    >
      <ReactDayPicker
        initialFocus={open}
        defaultMonth={date || today}
        fixedWeeks={true}
        weekStartsOn={1} // Monday
        selected={date}
        {...props}
      />
      {date && (
        <div className='-mt-4'>
          <Button
            testId='clear-day-button'
            type='button'
            className='p-3 text-red'
            onClick={() => onClear?.()}
          >
            {t('generic.clear')}
          </Button>
        </div>
      )}
    </div>
  )
}

export default DayPicker
