import {
  BusinessOwnerInput,
  InviteMainContractor,
  useGetNetworkCustomersLocationsQuery,
  useGetOnboardingConfigurationsForPlatformQuery,
  useInviteMainContractorsMutation,
} from '@wise/graphql'
import {
  formatDateAndTime,
  invariant,
  isDefined,
  isNonEmptyString,
  showToastNotification,
  toOptionList,
} from '@wise/utils'
import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { kebabCase } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import AddButton from '~shared/components/Buttons/AddButton/AddButton'
import Button from '~shared/components/Buttons/Button/Button'
import Error from '~shared/components/Form/Error'
import CrossSvg from '~shared/components/Icons/svg/cross.svg'
import ApiErrorBlock from '~shared/components/Import/ApiErrorBlock'
import DayInput from '~shared/components/Input/DayInput'
import Input from '~shared/components/Input/Input'
import Select from '~shared/components/Input/Select'
import TimePicker from '~shared/components/Input/TimePicker/TimePicker'
import { triggerErrorModal } from '~shared/components/Modal/preset/GenericModal/GenericModal'
import { WiseTrans } from '~shared/components/WiseTrans'

import Checkbox from '@/mc-onboarding/components/Checkbox'
import {
  InviteMCsState,
  useInviteMCsState,
} from '@/mc-onboarding/state/invite-mcs'

import ModalHeader from '../../../UI/ModalHeader'
import { ModalProps, removeModal } from '../../useModal'

import { isOption } from '~types'

interface Props {
  platformId: string
}

const formatBusinessOwner = (
  businessOwner: InviteMCsState['mainContractors'][number]['businessOwner'],
): BusinessOwnerInput | null => {
  if (!businessOwner) return null

  const onboardingConfigurationID = businessOwner.configId
  const externalID = businessOwner.externalId?.trim()

  invariant(
    isNonEmptyString(onboardingConfigurationID),
    'onboardingConfigurationID is required',
  )

  const interviewDateTime =
    businessOwner.needsInterview && businessOwner.interviewDateTime
      ? formatDateAndTime(
          businessOwner.interviewDateTime.date,
          businessOwner.interviewDateTime.time!,
        ) ?? 'error'
      : null

  invariant(interviewDateTime !== 'error', 'interviewDateTime is required')

  return {
    onboardingConfigurationID,
    externalID: isNonEmptyString(externalID) ? externalID : null,
    interviewDateTime,
  }
}

const CreatePartnerModal = ({
  id: modalId,
  platformId,
}: ModalProps & Props): JSX.Element => {
  const { t } = useTranslation()
  const {
    data: customorsLocationsData,
    error,
    loading,
  } = useGetNetworkCustomersLocationsQuery({
    variables: { networkId: platformId },
  })
  const { state, errors, validate, dispatch, resetErrors } = useInviteMCsState({
    needVisitDate: false,
  })

  const { customers, depots } = React.useMemo(() => {
    const networkCustomers =
      customorsLocationsData?.getNetwork.__typename === 'Network'
        ? customorsLocationsData.getNetwork.customers?.filter(isDefined) ?? []
        : []

    const customers = toOptionList({
      items: networkCustomers,
      label: 'name',
      value: 'id',
    })

    const depots =
      state.customerId === null
        ? []
        : toOptionList({
            items: networkCustomers?.find((c) => c?.id === state.customerId)
              ?.locations,
            label: (item) => item.name + (item.code ? ` (${item.code})` : ''),
            value: 'id',
          })

    return { customers, depots }
  }, [customorsLocationsData, state.customerId])

  const rolesQuery = useGetOnboardingConfigurationsForPlatformQuery({
    variables: {
      networkId: platformId,
    },
  })

  const roles = React.useMemo(() => {
    const list =
      rolesQuery.data?.onboardingConfigurations
        ?.filter(
          (oc) =>
            oc?.active &&
            oc?.customer?.id === state.customerId &&
            oc?.roleType === 'BUSINESS_OWNER', // This part may change in the future
        )
        .filter(isDefined) ?? []

    const options = toOptionList({
      items: list,
      label: (item) => item.name,
      value: 'id',
    })

    return { list, options }
  }, [rolesQuery.data?.onboardingConfigurations, state.customerId])

  const [inviteMainContractors] = useInviteMainContractorsMutation()
  const handleSubmit = React.useCallback(async () => {
    try {
      const outcome = validate()
      if (outcome.result === 'error') return
      const input = outcome.data
      const { data } = await inviteMainContractors({
        variables: {
          input: {
            networkId: platformId,
            customerId: input.customerId,
            locationID: input.depotId,
            visitDate: input?.visitDate,
            mainContractors: input.mainContractors.map<InviteMainContractor>(
              (mc) => {
                const businessOwner: BusinessOwnerInput | null =
                  formatBusinessOwner(mc.businessOwner)

                return {
                  companyName: mc.companyName,
                  email: mc.email,
                  firstName: mc.firstName,
                  lastName: mc.lastName,
                  businessOwner,
                }
              },
            ),
          },
        },
      })
      invariant(
        data?.inviteMainContractors === true,
        t('invite_main_contractors_page.submit.modal.issue'),
      )
      showToastNotification({
        type: 'success',
        description: t('invite_main_contractors_page.submit.toast.success'),
      })
      dispatch({ type: 'reset' })
      resetErrors()
      removeModal(modalId)
    } catch (error) {
      triggerErrorModal({ error })
    }
  }, [
    dispatch,
    inviteMainContractors,
    modalId,
    platformId,
    resetErrors,
    t,
    validate,
  ])

  const configNeedsInterview = React.useCallback(
    (configId: string) =>
      roles.list.some((r) => r.id === configId && r.interviewEnabled),
    [roles.list],
  )

  return (
    <div className='w-screen min-w-600 max-w-1000 rounded-xl bg-white p-12 shadow-md'>
      <ModalHeader>Add a new partner.</ModalHeader>
      <ApiErrorBlock className='mb-3' error={error} />
      <div className='my-5'>
        <h1 className='mb-2 text-xl font-bold'>
          {t('invite_main_contractors_page.customer-details.heading')}
        </h1>
        <div className='flex flex-row items-start gap-6'>
          <Select
            label={t(
              'invite_main_contractors_page.customer-details.select.customer',
            )}
            testId='customer-dropdow-menu'
            className='w-full min-w-300 max-w-400'
            error={errors['customerId']}
            selectProps={{
              options: customers,
              isDisabled: loading,
              isLoading: loading,
              value:
                customers.find(
                  (customer) => customer.value === state.customerId,
                ) ?? null,
              onChange: (option) => {
                const customerId = isOption(option)
                  ? String(option.value)
                  : null
                dispatch({ type: 'set-customer-id', value: customerId })
              },
            }}
          />
          <Select
            label={t(
              'invite_main_contractors_page.customer-details.select.depot',
            )}
            testId='depot-dropdown-menu'
            className='w-full min-w-300 max-w-400'
            error={errors['depotId']}
            selectProps={{
              isDisabled: state.customerId === null,
              options: depots,
              isLoading: loading,
              value:
                depots.find((depot) => depot.value === state.depotId) ?? null,
              onChange: (option) =>
                dispatch({
                  type: 'set-depot-id',
                  value: isOption(option) ? String(option.value) : null,
                }),
            }}
          />
        </div>
      </div>
      <div className='my-5'>
        <h1 className='mb-2 text-xl font-bold'>
          <WiseTrans
            i18nKey='invite_partners_modal.partners_to_invite.heading'
            components={{
              circle: (
                <span className='inline-block rounded-full bg-purple px-2.5 py-1 text-sm text-white' />
              ),
            }}
            values={{ inviteAmount: state.mainContractors.length }}
          />
        </h1>
        <AnimatePresence initial={false}>
          {state.mainContractors.map((mainContractor, ix) => {
            const needsInterview = mainContractor.businessOwner?.configId
              ? configNeedsInterview(mainContractor.businessOwner.configId)
              : false

            return (
              <motion.div
                key={mainContractor.id}
                initial={{ height: 0, opacity: 0, overflow: 'hidden' }}
                animate={{ height: 'auto', opacity: 1, overflow: 'unset' }}
                exit={{ height: 0, opacity: 0, overflow: 'hidden' }}
              >
                <div
                  className={cx(
                    'relative rounded-lg bg-[#f9f9f9] px-4 py-3',
                    ix > 0 && 'mt-3',
                  )}
                >
                  <div className='flex flex-row items-center'>
                    <div className='flex flex-1 flex-col gap-1'>
                      <div className='flex flex-row items-center gap-6'>
                        <Input
                          testId='first-name-input'
                          type='text'
                          className='flex-1'
                          label={t(
                            'invite_main_contractors_page.main-contractors-to-invite.input.first-name',
                          )}
                          value={mainContractor.firstName}
                          error={
                            errors[
                              `mainContractors.${mainContractor.id}.firstName`
                            ]
                          }
                          onChange={(e) =>
                            dispatch({
                              type: 'set-main-contractor-first-name',
                              id: mainContractor.id,
                              value: e.currentTarget.value,
                            })
                          }
                        />
                        <Input
                          testId='last-name-input'
                          type='text'
                          className='flex-1'
                          label={t(
                            'invite_main_contractors_page.main-contractors-to-invite.input.last-name',
                          )}
                          value={mainContractor.lastName}
                          error={
                            errors[
                              `mainContractors.${mainContractor.id}.lastName`
                            ]
                          }
                          onChange={(e) =>
                            dispatch({
                              type: 'set-main-contractor-last-name',
                              id: mainContractor.id,
                              value: e.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className='flex flex-1 flex-row items-center gap-6'>
                        <Input
                          testId='company-name-input'
                          type='text'
                          className='flex-1'
                          label={t(
                            'invite_main_contractors_page.main-contractors-to-invite.input.company-name',
                          )}
                          value={mainContractor.companyName}
                          error={
                            errors[
                              `mainContractors.${mainContractor.id}.companyName`
                            ]
                          }
                          onChange={(e) =>
                            dispatch({
                              type: 'set-main-contractor-company-name',
                              id: mainContractor.id,
                              value: e.currentTarget.value,
                            })
                          }
                        />
                        <Input
                          testId='email-address-input'
                          type='text'
                          className='flex-1'
                          label={t(
                            'invite_main_contractors_page.main-contractors-to-invite.input.email-address',
                          )}
                          value={mainContractor.email}
                          error={
                            errors[`mainContractors.${mainContractor.id}.email`]
                          }
                          onChange={(e) =>
                            dispatch({
                              type: 'set-main-contractor-email',
                              id: mainContractor.id,
                              value: e.currentTarget.value,
                            })
                          }
                        />
                      </div>
                      <div className='flex flex-1 flex-row items-center gap-6'>
                        <div className='-mx-2 flex cursor-pointer flex-row items-center rounded-md px-2 py-0.5 hover:bg-[#f0f0f0]'>
                          <Checkbox
                            id='business-owner-checkbox'
                            checked={mainContractor.businessOwner !== null}
                            label='Invite as Business Owner'
                            onCheck={(checked) => {
                              if (checked) {
                                return dispatch({
                                  type: 'set-main-contractor-business-owner',
                                  id: mainContractor.id,
                                  value: {
                                    configId: null,
                                    externalId: null,
                                    interviewDateTime: null,
                                    needsInterview: false,
                                  },
                                })
                              }
                              dispatch({
                                type: 'unset-main-contractor-business-owner',
                                id: mainContractor.id,
                              })
                            }}
                          />
                          <label
                            htmlFor='business-owner-checkbox'
                            className='cursor-pointer text-sm'
                          >
                            Invite as Business Owner
                          </label>
                        </div>
                      </div>
                      <AnimatePresence>
                        {mainContractor.businessOwner !== null ? (
                          <motion.div
                            initial={{ height: 0 }}
                            animate={{ height: 'auto' }}
                            exit={{ height: 0, overflow: 'hidden' }}
                            className='grid grid-cols-2 items-start gap-3 lg:grid-cols-4'
                          >
                            <Select
                              label='Role'
                              className='min-w-200'
                              error={
                                errors[
                                  `mainContractors.${mainContractor.id}.businessOwner.configId`
                                ]
                              }
                              selectProps={{
                                options: roles.options,
                                value:
                                  roles.options.find(
                                    (o) =>
                                      o.value ===
                                      mainContractor.businessOwner?.configId,
                                  ) ?? null,
                                isLoading: rolesQuery.loading,
                                isDisabled:
                                  rolesQuery.loading ||
                                  state.customerId === null,
                                onChange: (o) => {
                                  const id = isOption(o)
                                    ? String(o.value)
                                    : null
                                  dispatch({
                                    id: mainContractor.id,
                                    type: 'update-main-contractor-business-owner',
                                    value: {
                                      interviewDateTime: null,
                                      needsInterview: id
                                        ? configNeedsInterview(id)
                                        : false,
                                      configId: isOption(o)
                                        ? String(o.value)
                                        : null,
                                    },
                                  })
                                },
                              }}
                            />
                            <Input
                              label='External ID'
                              fieldStatus='optional'
                              value={
                                mainContractor.businessOwner.externalId || ''
                              }
                              onChange={(e) =>
                                dispatch({
                                  id: mainContractor.id,
                                  type: 'update-main-contractor-business-owner',
                                  value: { externalId: e.target.value },
                                })
                              }
                            />
                            <DayInput
                              label='Interview Date'
                              isInputDisabled={!needsInterview}
                              error={
                                errors[
                                  `mainContractors.${mainContractor.id}.businessOwner.interviewDateTime`
                                ] ||
                                errors[
                                  `mainContractors.${mainContractor.id}.businessOwner.interviewDateTime.date`
                                ]
                              }
                              onDayChange={(e) => {
                                dispatch({
                                  id: mainContractor.id,
                                  type: 'update-main-contractor-business-owner',
                                  value: {
                                    interviewDateTime: { date: e ?? null },
                                  },
                                })
                              }}
                              date={
                                mainContractor.businessOwner.interviewDateTime
                                  ?.date ?? undefined
                              }
                            />
                            <TimePicker
                              label='Interview Time'
                              minuteGap={5}
                              disabled={!needsInterview}
                              startFrom={540}
                              error={
                                errors[
                                  `mainContractors.${mainContractor.id}.businessOwner.interviewDateTime.time`
                                ]
                              }
                              onChange={(e) =>
                                dispatch({
                                  type: 'update-main-contractor-business-owner',
                                  id: mainContractor.id,
                                  value: { interviewDateTime: { time: e } },
                                })
                              }
                              value={
                                mainContractor.businessOwner.interviewDateTime
                                  ?.time
                              }
                              placeholder=''
                            />
                          </motion.div>
                        ) : null}
                      </AnimatePresence>
                    </div>
                    <button
                      data-testid={`${kebabCase(
                        mainContractor.firstName.length > 0
                          ? mainContractor.firstName
                          : 'maincontractor',
                      )}-delete-button`}
                      className='absolute top-1 right-1'
                      onClick={() =>
                        dispatch({
                          type: 'remove-main-contractor',
                          id: mainContractor.id,
                        })
                      }
                    >
                      <div className='flex h-8 w-8 items-center justify-center rounded-full text-red hover:bg-red hover:text-white'>
                        <CrossSvg />
                      </div>
                    </button>
                  </div>
                </div>
              </motion.div>
            )
          })}
        </AnimatePresence>
        <Error>{errors['mainContractors']}</Error>
        <AddButton
          testId='add-main-contractor-button'
          className='mt-4 mr-auto ml-0'
          onClick={() => dispatch({ type: 'add-main-contractor' })}
        >
          <WiseTrans
            i18nKey='invite_partners_modal.button.add-partner'
            components={{
              circle: (
                <span className='mr-3 flex h-4 w-4 items-center justify-center rounded-full bg-white text-sm text-theme' />
              ),
            }}
          />
        </AddButton>
      </div>
      <div className='flex flex-row items-center justify-end gap-3'>
        <Button
          testId='cancel-modal-button'
          autoFocus
          type='button'
          className='rounded-full py-3 px-8 text-sm underline'
          onClick={() => removeModal(modalId)}
        >
          {t('generic.cancel')}
        </Button>
        <Button
          testId={`modal-confirm-button`}
          type='button'
          className='rounded-full bg-green px-8 py-3 text-sm font-bold text-white shadow-md'
          onClick={handleSubmit}
        >
          {t('generic.confirm')}
        </Button>
      </div>
    </div>
  )
}

export default CreatePartnerModal
