import { getTimeOfDayOptions, randomString } from '@wise/utils'
import * as React from 'react'
import { MenuPosition, createFilter } from 'react-select'

import { isOption } from '../../../../types'
import Select from '../Select'

interface Props {
  onChange?: (option: Optional<string>) => void
  onBlur?: () => void
  placeholder?: string
  minuteGap?: number
  startFrom?: number
  label?: string
  value?: Optional<string>
  error?: React.ComponentProps<typeof Select>['error']
  testId?: string
  className?: string
  menuPosition?: MenuPosition
  disabled?: boolean
}

const TimePicker = ({
  testId,
  onChange,
  onBlur,
  placeholder,
  value,
  label,
  minuteGap = 5, // 5 minute gaps
  startFrom = 540, // 9am
  error,
  className,
  menuPosition = undefined,
  disabled = false,
}: Props): JSX.Element => {
  const [id] = React.useState(() => randomString())

  const options = React.useMemo(() => {
    return getTimeOfDayOptions(minuteGap, startFrom)
  }, [minuteGap, startFrom])

  return (
    <Select
      id={id}
      label={label}
      error={error}
      testId={testId}
      className={className}
      selectProps={{
        onBlur,
        onChange: (v) => onChange?.(isOption(v) ? String(v.value) : null),
        value: value
          ? options.find((opt) => opt.value === String(value))
          : null,
        isClearable: true,
        isDisabled: disabled ?? false,
        placeholder,
        options,
        menuPosition,
        filterOption: createFilter({ matchFrom: 'start' }),
      }}
    />
  )
}

export default React.memo(TimePicker)
