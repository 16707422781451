import Tooltip from '@tippyjs/react'
import type { McpNotificationFragment } from '@wise/graphql'
import { isNonEmptyString } from '@wise/utils'
import { motion } from 'framer-motion'
import { useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import NotificationIcon from '~shared/components/Icons/svg/bell2.svg'

import { useMaincontractor } from '@/maincontractors/hooks/useMaincontractor'

import { useNotifications } from '../NotificationsProvider/useNotifications'

import NotificationsHeader from './components/NotificationsHeader'
import NotificationsWrapper from './components/NotificationsWrapper'
import { getNotificationCategories } from './utils/categories'

const Notifications = (): JSX.Element | null => {
  const mc = useMaincontractor()
  const {
    notifications,
    loading,
    error,
    unread,
    status,
    enabled: notificationsEnabled,
    markAsRead,
  } = useNotifications()

  const [search, setSearch] = useState<string>('')
  const [category, setCategory] = useState<
    McpNotificationFragment['category'] | null
  >(null)

  const categories = useMemo((): McpNotificationFragment['category'][] => {
    return getNotificationCategories(notifications)
  }, [notifications])

  if (!notificationsEnabled || !isNonEmptyString(mc?.id)) return null

  return (
    <motion.div
      layoutId='top-bar--notifications'
      className='flex flex-row items-center text-xl'
    >
      <Tooltip
        className='min-w-max'
        theme='light'
        interactive
        animation='shift-away'
        placement='bottom-end'
        trigger='click'
        arrow={false}
        content={
          <>
            <NotificationsHeader
              status={status}
              search={search}
              setSearch={setSearch}
              category={category}
              setCategory={setCategory}
              categories={categories}
            />
            {error ? (
              <div className='mb-3 min-w-400 rounded-md bg-red/20 p-2 px-3 py-5'>
                <p className='font-bold text-red-300'>An error has occurred:</p>
                <p>{error.message}</p>
              </div>
            ) : null}
            <NotificationsWrapper
              notifications={notifications}
              search={search}
              category={category}
              areCategoriesVisible={categories.length > 0}
              markAsRead={markAsRead}
            />
          </>
        }
        disabled={loading ? true : false}
      >
        <button
          data-testid='top-bar-notification-icon'
          className='relative flex h-16 w-12 items-center justify-center'
        >
          <NotificationIcon className='h-8 w-8 text-theme' />
          <div
            className={twMerge(
              'absolute top-[12px] right-[11px] flex aspect-square h-[10px] w-[10px] flex-col justify-center rounded-full border',
              unread > 0 ? 'border-red bg-red' : 'border-theme',
            )}
          />
        </button>
      </Tooltip>
    </motion.div>
  )
}

export default Notifications
